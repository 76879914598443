import React from "react";

const Footer = () => {
  return (
    <div className="bg-black  w-full text-white  pt-[100px] px-[25px] pb-[25px] ">
      <div
        className="h-full w-full rounded-lg pt-[25px]"
        style={{ boxShadow: "0 0 20px -3px red" }}
      >
        <div className="flex justify-center">
          <ul className="flex justify-between  w-[200px] min-[600px]:w-[230px] p-[30px]">
            <li>
              <a
                href="https://www.linkedin.com/in/madhav1501/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../assets/linkedin.png"
                  alt="icon's"
                  className="h-[20px] min-[600px]:h-[25px] bg-white rounded-full"
                  style={{ boxShadow: "0 0 5px 0 red" }}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/madhavv.1209/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../assets/instagram.png"
                  alt="icon's"
                  className="h-[20px] min-[600px]:h-[25px] bg-white rounded-full"
                  style={{ boxShadow: "0 0 5px 0 red" }}
                />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/madhavan1501"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../assets/github.png"
                  alt="icon's"
                  className="h-[20px] min-[600px]:h-[25px] bg-white rounded-full"
                  style={{ boxShadow: "0 0 5px 0 red" }}
                />
              </a>
            </li>
            <li>
              <a href="mailto:madhanraju1501@zohomail.in">
                <img
                  src="../assets/email.png"
                  alt="icon's"
                  className="h-[20px] min-[600px]:h-[25px] bg-white rounded-full"
                  style={{ boxShadow: "0 0 5px 0 red" }}
                />
              </a>
            </li>
          </ul>
        </div>
        <div
          style={{ wordSpacing: "3px" }}
          className="text-center text-[15px] min-[600px]:text-[18px] tracking-[2px] p-[10px]"
        >
          ©2024.
          <span className="text-red-500 text-[18px] min-[600px]:text-[20px]">
            A
          </span>
          ll R<span className="text-red-500 ">i</span>ghts Reserved
        </div>
        <div
          style={{ wordSpacing: "5px" }}
          className="flex flex-col min-[600px]:flex-row justify-center text-[18px] min-[600px]:text-[20px] tracking-[2px] items-center pb-[25px]"
        >
          <p>
            <span className="text-red-500  text-[22px] min-[600px]:text-[25px]">
              D
            </span>
            esigned &{" "}
            <span className="text-red-500  text-[22px] min-[600px]:text-[25px]">
              D
            </span>
            eveloped by-
          </p>
          <p className="pl-[10px] text-[22px] min-[600px]:text-[25px] ">
            Madha<span className="text-red-500 ">n</span>{" "}
            <span className="text-red-500">R</span>aju
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
