import { React, useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    const txtContact = document.querySelector("#txtContact");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        if (e.isIntersecting) {
          e.target.style.bottom = 0;
        } else {
          e.target.style.bottom = `-100%`;
        }
      });
    });
    observer.observe(txtContact);
  }, []);
  return (
    <div className="bg-black overflow-hidden min-h-screen text-white pt-[100px] px-[25px] min-[400px]:px-[50px]">
      <div className=" flex justify-start items-center h-[55px] py-10">
        <div className="border-red-500 border-b-[2px] w-[300px] h-[50px] overflow-hidden leading-[50px] relative">
          <p
            id="txtContact"
            className="text-red-500 text-[40px] text-nowrap absolute w-fit left-0 -bottom-full transition-all duration-1000 "
          >
            Let's Collaborate
          </p>
        </div>
      </div>
      <p
        id="contact"
        className=" pt-[50px] text-[25px] capitalize indent-[25px]  min-[600px]:indent-[100px] min-[1000px]:indent-0 text-justify min-[1100px]:text-center  p-[25px] min-[400px]:p-[50px]"
      >
        “Let’s create something amazing{" "}
        <span id="txtContact" className="text-red-500 ">
          together!
        </span>{" "}
        Whether you have a project idea, need assistance, or just want to chat,
        I’m here. Reach out, and{" "}
        <span id="txtContact" className="text-red-500 ">
          let's
        </span>{" "}
        collaborate!”
      </p>
      <div className="pt-[50px]  normal-case grid min-[700px]:grid-cols-[200px_auto] justify-center min-[700px]:pt-[50px] gap-x-[0px] min-[1200px]:gap-x-[100px] gap-y-[10px] min-[700px]:gap-y-[25px]">
        <div
          style={{ boxShadow: "0 5px 10px -5px red" }}
          className=" self-start  pt-[50px] justify-self-start min-[700px]:pt-0 w-fit text-[23px] min-[500px]:text-[25px] "
        >
          Linked <span className="text-red-500 ">I</span>n:
        </div>
        <div
          style={{
            textDecoration: "underline solid white 1.1px",
            textUnderlineOffset: "3px",
          }}
          className="text-[23px] min-[500px]:text-[25px] "
        >
          <a
            href="https://www.linkedin.com/in/madhav1501/"
            target="_blank"
            rel="noreferrer"
            className="hover:text-red-500 transition-all duration-500"
          >
            {" "}
            Madha<span className="text-red-500 ">n</span>{" "}
            <span className="text-red-500 ">R</span>aju
          </a>
        </div>
        <div
          style={{ boxShadow: "0 5px 10px -5px red" }}
          className=" self-start  pt-[50px] justify-self-start min-[700px]:pt-0 w-fit text-[23px] min-[500px]:text-[25px]"
        >
          What's App:
        </div>
        <div className="text-[23px] min-[500px]:text-[25px]">
          <span className="text-red-500 ">+</span>91 9080299749
        </div>
        <div
          style={{ boxShadow: "0 5px 10px -5px red" }}
          className=" self-start  pt-[50px] justify-self-start min-[700px]:pt-0 w-fit text-[23px] min-[500px]:text-[25px]"
        >
          <span className="text-red-500 ">I</span>nstagram:
        </div>
        <div
          style={{ wordSpacing: "10px", letterSpacing: "2px" }}
          className="text-[20px] min-[500px]:text-[25px]"
        >
          <span
            style={{
              textDecoration: "underline solid white 1.1px",
              textUnderlineOffset: "3px",
            }}
          >
            <a
              href="https://www.instagram.com/madhavan.1209/"
              target="_blank"
              rel="noreferrer"
              className="hover:text-red-500 transition-all duration-500"
            >
              {" "}
              @madhavan.1209
            </a>
          </span>{" "}
          (Tech~Page) ||{" "}
          <span
            style={{
              textDecoration: "underline solid white 1.1px",
              textUnderlineOffset: "3px",
            }}
          >
            <a
              href="https://www.instagram.com/madhav.1501/"
              target="_blank"
              rel="noreferrer"
              className="hover:text-red-500 transition-all duration-500"
            >
              {" "}
              @mad<span className="text-red-500 ">h</span>av.1501
            </a>
          </span>{" "}
          (Personal)
        </div>
        <div
          style={{ boxShadow: "0 5px 10px -5px red" }}
          className=" self-start  pt-[50px] justify-self-start min-[700px]:pt-0 w-fit text-[23px] min-[500px]:text-[25px]"
        >
          Email:
        </div>
        <div
          style={{ wordSpacing: "20px", letterSpacing: "2px" }}
          className="text-[20px] min-[500px]:text-[25px] "
        >
          <span
            style={{
              textDecoration: "underline solid white 1.1px",
              textUnderlineOffset: "3px",
            }}
          >
            <a
              href="mailto:madhanraju1209@gmail.com"
              className="hover:text-red-500 transition-all duration-500"
            >
              {" "}
              M<span className="text-red-500 ">a</span>dhanraju1209@gmail.com
            </a>
          </span>{" "}
          ||{" "}
          <span
            style={{
              textDecoration: "underline solid white 1.1px",
              textUnderlineOffset: "3px",
            }}
          >
            <a
              href="mailto:madhanraju1501@zohomail.in"
              className="hover:text-red-500 transition-all duration-500"
            >
              {" "}
              Madhanraju1501@<span className="text-red-500 ">Z</span>
              <span className="text-green-500 ">O</span>
              <span className="text-blue-500 ">H</span>
              <span className="text-yellow-500 ">O</span>mail.in
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
