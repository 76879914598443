import { useEffect, useRef, useState } from "react";
import SenjuLogo from "./assets/SenjuLogo";

function Intro() {
  const ref = useRef("");
  const [strokeWidth, setstrokeWidth] = useState(
    window.innerWidth < 800 ? 2 : 4
  );
  const [xAxis, setxAxis] = useState(window.innerWidth < 499 ? 0 : 80);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setstrokeWidth(window.innerWidth < 800 ? 2 : 4);
      setxAxis(window.innerWidth < 499 ? 0 : 80);
    });
  }, []);
  setTimeout(() => {
    ref.current.style.opacity = 0;
    ref.current.style.setProperty("transition", "opacity 2s linear");
  }, 10000);
  setTimeout(() => {
    ref.current.style.setProperty("z-index", -1000);
  }, 12000);
  return (
    <div
      ref={ref}
      className="grid place-items-center h-[100vh] bg-black absolute inset-0 z-[1000] overflow-hidden"
    >
      <div className="flex  min-[300px]:flex-col min-[1000px]:flex-row justify-evenly items-center">
        <div>
          <svg
            id="intro-text-animation"
            height="250"
            width="500"
            className="font-serif "
          >
            <text
              x={xAxis}
              y="60%"
              className="min-[300px]:text-[90px] min-[1000px]:text-[120px]"
              // className="text-[120px]"
              fill="black"
              // stroke="white"
              strokeWidth={strokeWidth}
            >
              Madhan
            </text>
          </svg>
        </div>
        {/* <div>
          <svg height="250" width="100" className="bg-orange-500"></svg>
        </div> */}
        <div className="relative w-[150px] min-[300px]:w-[250px] min-[1000px]:w-[150px] h-[50px]">
          <SenjuLogo />
        </div>
        <div>
          <svg
            id="intro-text-animation"
            height="250"
            width="500"
            className="font-serif "
          >
            <text
              x={30}
              y="60%"
              className="text-[120px]"
              fill="black"
              // stroke="white"
              strokeWidth={strokeWidth}
            >
              Raju
            </text>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Intro;
