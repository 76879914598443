import React, { useEffect } from "react";

const Projects = () => {
  useEffect(() => {
    const projects = document.querySelector("#projects");

    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        if (e.isIntersecting) {
          e.target.style.bottom = 0;
        } else {
          e.target.style.bottom = `-100%`;
        }
      });
    });
    observer1.observe(projects);

    // ---------------------------------------------------------------------------------------------------------------

    const cardContainer = document.querySelector("#cardContainer");

    const observer2 = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          if (e.isIntersecting) {
            cardIntersect();
            observer2.unobserve(cardContainer);
          }
        });
      },
      { threshold: 0.75 }
    );

    observer2.observe(cardContainer);

    // ------------------------------------------------------------------------------------------------------------------
  }, []);

  const cardIntersect = () => {
    const cardPain1 = document.querySelector("#pain1");
    const cardPain2 = document.querySelector("#pain2");
    const cardPain3 = document.querySelector("#cardPain3");
    const cardKonan = document.querySelector("#konan");
    const cardNagato = document.querySelector("#nagato");
    const btnShow = document.querySelector("#btnShow");
    const root = document.querySelector(":root");

    cardPain1.style.left = `50%`;
    cardPain2.style.right = `50%`;

    setTimeout(() => {
      cardPain1.style.opacity = `0`;
      cardPain2.style.opacity = `0`;

      setTimeout(() => {
        cardPain1.style.setProperty("z-index", "-1000");
        cardPain2.style.setProperty("z-index", "-1000");
        cardPain3.style.opacity = 1;

        setTimeout(() => {
          cardKonan.style.setProperty("visibility", "visible");
          cardNagato.style.setProperty("visibility", "visible");
        }, 1000);
        root.style.setProperty(
          "--animateKonan",
          "1s ease-out 1s forwards konan"
        );
        root.style.setProperty(
          "--animateNagato",
          "1s ease-out 1s forwards nagato"
        );
        setTimeout(() => {
          btnShow.style.cssText = `opacity:1;box-shadow:0 0 10px 0 red;`;
        }, 2000);
      }, 1000);
    }, 1000);
  };

  const showProject = () => {
    const root = document.querySelector(":root");
    const cardNagato = document.querySelector("#nagato");
    const cardKonan = document.querySelector("#konan");
    const cardPain3 = document.querySelector("#cardPain3");
    const btnShow = document.querySelector("#btnShow");
    // const Pain3 = document.querySelector("#pain3");

    root.style.setProperty("--animateKonan", "2s ease-out  forwards konan2");
    root.style.setProperty("--animateNagato", "2s ease-out  forwards nagato2");
    root.style.setProperty(
      "--animatePain",
      "3000ms linear 1000ms  forwards cardPain3"
    );
    root.style.setProperty(
      "--animatePain2",
      "2100ms linear 1000ms  forwards pain2"
    );
    // Pain3.style.setProperty("transform", "rotateZ(90deg)");
    btnShow.style.opacity = 0;
    setTimeout(() => {
      cardPain3.innerHTML = `<a href="https://amazon1501.netlify.app/" target="_blank"> <video
      
      id="amazonClone"
      style="transform:rotateY(180deg) "
      muted
      autoPlay
      loop
    >
    <source src="../assets/amazonClone.mp4"/> 
    <source src="../assets/amazonClone.webM"/> 
    </video> </a>`;
    }, 3550);
    setTimeout(() => {
      cardNagato.innerHTML = `<a href="https://madhavan1501.github.io/tic_tac_toe/" target="_blank"> <video
      id="ticTakToe"
      style="transform:rotateY(180deg)"
      muted
      autoPlay
      loop
    > 
    <source src="../assets/ticTakToe.mp4"/> 
    <source src="../assets/ticTakToe.webM"/> 
    </video> </a>`;
      cardKonan.innerHTML = `<a href="https://madhavan1501.github.io/password-generator/" target="_blank"> <video
      
      id="passwordGen"
      style="transform:rotateY(180deg)"
      muted
      autoPlay
      loop
    >
      <source src="../assets/passwordGen.mp4"/>
      <source src="../assets/passwordGen.webM"/>
    </video> </a>`;
      btnShow.style.setProperty("display", "none");
    }, 1900);

    setTimeout(() => {
      root.style.setProperty("--cardLabel", "1");
    }, 4000);
  };

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // window.addEventListener("resize", () => {
  //   // console.log(window.innerWidth);
  //   setWindowWidth(window.innerWidth);
  // });
  return (
    <div className="bg-black w-full overflow-hidden text-white pt-[100px] pb-[100px] min-h-screen flex flex-col gap-y-[30px]">
      {" "}
      <div className=" flex justify-center items-center h-[55px]  ">
        <div className="border-red-500 border-b-[2px] w-[190px] h-[50px] overflow-hidden leading-[50px] relative ">
          <p
            id="projects"
            // onClick={fun}
            className="text-red-500 text-[50px] text-nowrap absolute w-fit left-1/2 -translate-x-1/2 -bottom-full transition-all duration-1000"
          >
            Projects
          </p>
        </div>
      </div>
      <div className=" h-[700px] min-[700px]:h-[350px] min-[900px]:h-[500px]  w-full flex  items-center justify-center">
        <div
          id="cardContainer"
          className="  shrink-0 w-[700px] h-[350px] min-[700px]:h-[350px] min-[700px]:w-full min-[900px]:h-[500px] relative  flex items-center  rotate-90 min-[700px]:rotate-0"
        >
          <div
            id="pain1"
            className="  transition-all duration-[1000ms] ease-out  absolute left-0 "
          >
            <img
              src="../assets/projectCardPain2.png"
              alt="pain"
              className=" h-[150px] w-[80px]  min-[900px]:h-[200px] min-[900px]:w-[100px]  min-[1100px]:h-[300px] min-[1100px]:w-[125px] "
            />
          </div>
          <div
            id="pain2"
            className=" transition-all duration-[1000ms] ease-out  absolute right-0 "
          >
            <img
              src="../assets/projectCardPain1.png"
              alt="pain"
              className=" h-[150px] w-[80px]  min-[900px]:h-[200px] min-[900px]:w-[100px]  min-[1100px]:h-[300px] min-[1100px]:w-[125px] "
            />
          </div>
          {/* ----------------------------------------------------------------------------------------- */}
          <div
            id="nagato"
            className="object-contain  h-[150px] w-[100px]    min-[400px]:h-[200px] min-[400px]:w-[150px]     min-[900px]:h-[300px] min-[900px]:w-[200px]  min-[1200px]:h-[400px] min-[1200px]:w-[250px] "
          >
            <img src="../assets/projectCardNagato.jpg" alt="pain3" />
          </div>
          <div
            id="cardPain3"
            className="object-contain   h-[200px] w-[100px]   min-[400px]:h-[250px] min-[400px]:w-[150px]     min-[900px]:h-[350px] min-[900px]:w-[200px]  min-[1200px]:h-[500px] min-[1200px]:w-[250px] "
          >
            <img src="../assets/projectCardPain4.png" alt="pain3" id="pain3" />
          </div>
          <div
            id="konan"
            className="object-contain   h-[150px] w-[100px]   min-[400px]:h-[200px] min-[400px]:w-[150px]     min-[900px]:h-[300px] min-[900px]:w-[200px]  min-[1200px]:h-[400px] min-[1200px]:w-[250px]  "
          >
            <img src="../assets/projectCardKonan.jpg" alt="pain3" />
          </div>
          {/*-------------------------------------------------------------------------------------------  */}
        </div>
      </div>
      <div className="flex justify-center ">
        <button
          onClick={showProject}
          id="btnShow"
          className=" px-[50px] rounded-lg  z-[2000]  opacity-0 transition-opacity duration-1000 text-[25px]"
        >
          wanna see it
        </button>
      </div>
    </div>
  );
};

export default Projects;
