import React from "react";

const Home = () => {
  window.addEventListener("scroll", () => {
    const top_img = document.querySelector("#top-img");
    const bottom_img = document.querySelector("#bottom-img");
    const home_text = document.querySelector("#Home-text-animation");
    const obito = document.querySelector("#obito");
    const yAxis = window.scrollY;
    top_img.style.top = `${yAxis * 0.5}px`;
    home_text.style.top = `${yAxis * 0.4}px`;
    bottom_img.style.bottom = `${yAxis * 0.3 * -1}px`;
    obito.style.bottom = `${yAxis * 0.1 * -1}px`;
  });

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((e) => {
      if (e.isIntersecting) {
        e.target.style.setProperty("transform", "translateY(0)");
      } else {
        e.target.style.setProperty(
          "transform",
          `translateY(${e.target.dataset.int}px)`
        );
      }
    });
  });

  // useEffect(() => {
  //   observer.observe(document.querySelector("#Text-1"));
  //   observer.observe(document.querySelector("#Text-2"));
  // }, []);
  setTimeout(() => {
    observer.observe(document.querySelector("#Text-1"));
    observer.observe(document.querySelector("#Text-2"));
  }, 10100);

  return (
    <div
      id="home-div"
      className="h-full relative bg-gray-400 overflow-hidden select-none"
    >
      <div
        id="Home-text-animation"
        className="relative  z-[350] min-[850px]:z-[250]  h-full left-0 w-full min-[500px]:w-3/4 min-[500px]:translate-x-[30%]  min-[850px]:w-1/2 min-[850px]:translate-x-[65%] top-0  grid grid-rows-[45%_55%] min-[400px]:grid-rows-[40%_60%]                    min-[600px]:grid-rows-[20%_80%]"
      >
        {/* <div className="absolute  w-full grid grid-rows-2"> */}
        <div
          id="Text-1"
          data-int={-100}
          className="text-white text-[30px] self-end flex flex-col  items-center  h-[100px]  min-[400px]:flex-row    min-[400px]:h-[50px] min-[400px]:px-[30px] min-[600px]:text-[35px] min-[600px]:h-[45px]  min-[1400px]:text-[50px] min-[1400px]:h-[80px] transition-transform duration-[2000ms] -translate-y-[100px]"
        >
          <div className="h-[50%] w-full  text-center min-[400px]:h-[100%] min-[400px]:w-[70%] ">
            Hi, <span className="text-red-600">I</span>'m
          </div>
          <div className="h-[50%] w-full  overflow-hidden relative min-[400px]:h-[100%] min-[400px]:w-[120%] min-[800px]:w-full">
            <div id="home-text-parent" className="pl-16 min-[400px]:p-0">
              <p
                id="Home-text"
                className="min-[400px]:leading-[50px] min-[600px]:h-[50%] min-[600px]:leading-[60px]  min-[1400px]:leading-[80px]"
              >
                Web Developer..
              </p>
              <p
                id="Home-text"
                className="min-[400px]:leading-[50px] min-[600px]:h-[50%]  min-[600px]:leading-[60px] min-[1400px]:leading-[80px]"
              >
                Programmer..
              </p>
            </div>
          </div>
        </div>

        <div
          id="Text-2"
          data-int={200}
          className="text-white   text-[60px]   min-[660px]:text-[80px]  min-[850px]:text-[60px]  min-[1000px]:text-[80px]    min-[1250px]:text-[100px]   min-[1455px]:text-[120px] font-semibold transition-transform duration-[2000ms] translate-y-[200px] text-center row-span-2 self-start "
        >
          Madha<span className="text-red-600">n</span>{" "}
          <span className="text-red-600 ">R</span>aju
        </div>
        {/* </div> */}
      </div>
      <img
        id="top-img"
        alt="image_loding"
        className="absolute top-0 left-0 z-[200]  h-[60%] scale-x-[3] translate-x-[200px]  min-[400px]:translate-x-[80px]    min-[400px]:h-[50%] min-[400px]:scale-x-[1.8]     min-[500px]:translate-x-[60px]     min-[500px]:scale-x-[1.7]  min-[500px]:h-[62%]  min-[600px]:translate-x-[30px]   min-[600px]:scale-x-[1.5] min-[600px]:h-[62%] min-[800px]:h-[62%] min-[800px]:translate-x-0  min-[800px]:scale-x-[1.3]  min-[1000px]:scale-x-[1.2] min-[1000px]:h-[62%]  min-[1200px]:scale-x-[1] min-[1250px]:h-[68%] w-full bg-cover"
        src="../assets/bg-final-top2.png "
      />
      <img
        id="bottom-img"
        alt="image_loding"
        className="absolute bottom-0 left-0 z-[300]  scale-x-[2]   min-[400px]:-translate-x-[50px] min-[400px]:scale-x-[1.7]    min-[400px]:-translate-y-[80px] min-[400px]:scale-y-[1.2]        min-[500px]:translate-y-0                      min-[500px]:-translate-x-[50px] min-[500px]:scale-x-[1.5]   min-[600px]:scale-x-[1]       min-[600px]:translate-x-0    h-[85%] min-[800px]:h-[80%] min-[1250px]:h-[70%] w-full bg-cover"
        src="../assets/bg-bottom.png "
      />
      <div id="homeDiv-gradient"></div>
      <div
        id="obito"
        className="absolute z-[500] bottom-0          left-[50px]     min-[1250px]:left-[100px]  "
      >
        <div className="  h-[200px] w-[200px]   min-[850px]:h-[300px] min-[850px]:w-[300px]          min-[1250px]:h-[500px] min-[1250px]:w-[500px] relative  ">
          <img
            src="../assets/obito1.svg"
            alt="image_loding"
            className="absolute bottom-0 left-1/2 -translate-x-1/2"
          />
          <div
            id="animate-obito"
            className="absolute  origin-center    top-[-30px]  h-[150px] w-[150px]                          min-[850px]:h-[180px] min-[850px]:w-[180px]          min-[850px]:top-[-20px] min-[850px]:left-[20px]                 min-[1250px]:h-[200px] min-[1250px]:w-[200px]  min-[1250px]:top-[180px] min-[1250px]:left-[115px] "
          >
            <svg
              className=" absolute top-0 left-1/2  -translate-x-1/2"
              height={50}
              width={50}
            >
              <circle cx={25} cy={25} r={12} fill="black"></circle>
            </svg>
            <svg
              className=" absolute top-1/2 -translate-y-1/2 left-0"
              height={50}
              width={50}
            >
              <circle cx={25} cy={25} r={12} fill="black"></circle>
            </svg>

            <svg
              className=" absolute top-1/2 -translate-y-1/2 right-0"
              height={50}
              width={50}
            >
              <circle cx={25} cy={25} r={12} fill="black"></circle>
            </svg>
            <svg
              className=" absolute bottom-0 left-1/2  -translate-x-1/2"
              height={50}
              width={50}
            >
              <circle cx={25} cy={25} r={12} fill="black"></circle>
            </svg>
            <svg
              className=" absolute top-[10%] left-[10%]"
              height={50}
              width={50}
            >
              <circle cx={25} cy={25} r={12} fill="black"></circle>
            </svg>
            <svg
              className=" absolute top-[10%] right-[10%]"
              height={50}
              width={50}
            >
              <circle cx={25} cy={25} r={12} fill="black"></circle>
            </svg>
            <svg
              className=" absolute bottom-[10%] left-[10%]"
              height={50}
              width={50}
            >
              <circle cx={25} cy={25} r={12} fill="black"></circle>
            </svg>
            <svg
              className=" absolute bottom-[10%] right-[10%]"
              height={50}
              width={50}
            >
              <circle cx={25} cy={25} r={12} fill="black"></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
