import React, { useEffect } from "react";

const About = () => {
  useEffect(() => {
    const txtAbt = document.querySelector("#txtAbout");
    const txtFutureV = document.querySelector("#txtFv");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        if (e.isIntersecting) {
          e.target.style.bottom = 0;
        } else {
          e.target.style.bottom = `-100%`;
        }
      });
    });
    observer.observe(txtAbt);
    observer.observe(txtFutureV);
  }, []);
  return (
    <div className="bg-black min-h-full w-full relative capitalize overflow-hidden">
      <div className="min-h-full w-full relative bg-black flex flex-col-reverse min-[800px]:flex-row min-[800px]:py-[100px] capitalize">
        <div className=" w-full min-[800px]:w-3/4 h-full flex flex-col  px-[25px] min-[400px]:px-[50px] min-[1000px]:px-[100px] pb-[150px] min-[800px]:py-[200px]">
          <div className=" flex justify-start items-center h-[55px] py-10">
            <div className="border-red-500 border-b-[2px] w-[220px] h-[50px] overflow-hidden leading-[50px] relative">
              <p
                id="txtAbout"
                className="text-red-500 text-[50px] text-nowrap absolute w-fit left-0 -bottom-full transition-all duration-1000 "
              >
                About Me
              </p>
            </div>
          </div>
          <p
            style={{ wordSpacing: "2px" }}
            className="text-white text-[22px] min-[500px]:text-[25px] pt-[10px] text-justify"
          >
            <span className="text-red-500 text-[40px]">I</span>’m Madhan Raju{" "}
            <span className="text-red-500 text-[30px]">{"["}</span>
            <span className="text-[25px]">19</span>
            <span className="text-red-500 text-[30px]">{"]"}</span>, a
            passionate self-taught developer with a penchant for web
            technologies I embarked on my coding adventure after dropping out
            12th grade. While I may not have a formal degree, my hunger for
            knowledge drives me forward.
          </p>
          <p
            id="myGoal"
            className="text-white  text-[25px] min-[500px]:text-[30px] pt-[50px]"
          >
            <span className="text-red-500 text-[40px]"> M</span>y ultimate goal
            To be that person who never stops learning.
          </p>
        </div>
        <div className="  w-full min-[800px]:w-1/2 h-full flex justify-center  pt-[100px] pb-[50px] min-[800px]:py-[120px]">
          <img
            id="profileImg"
            src="../assets/profileImg.jpg"
            alt="profileImage"
            className=" h-[500px] min-[800px]:h-[400px] min-[1000px]:h-[500px] object-contain"
          />
        </div>
      </div>
      <div className="text-white bg-black text-[25px] min-[500px]:text-[28px] flex flex-col items-center ">
        <div className=" flex justify-center items-center h-[55px] ">
          <div className="border-red-500 border-b-[2px] w-[300px] h-[50px] overflow-hidden leading-[50px] relative">
            <p
              id="txtFv"
              className="text-red-500 text-[50px] text-nowrap absolute w-fit left-1/2 -translate-x-1/2 -bottom-full transition-all duration-1000"
            >
              Future Vision
            </p>
          </div>
        </div>{" "}
        <div className=" p-[25px] min-[400px]:p-[50px]">
          <p className="pb-[30px] text-center">
            <span className="text-red-500 text-[40px]">M</span>y compass points
            toward a horizon where I’m not just a coder.{" "}
            <span className="text-red-500 text-[40px]">I </span>
            want to be a tech-savvy polymath:
          </p>
          <div className="  normal-case grid min-[700px]:grid-cols-[200px_auto] justify-center min-[700px]:pt-[50px] gap-x-[100px] gap-y-[10px] min-[700px]:gap-y-[25px]">
            <div className="justify-self-center pt-[50px] min-[700px]:justify-self-start min-[700px]:pt-0 w-fit">
              Technology:
            </div>
            <div className="text-[23px] min-[500px]:text-[25px]">
              Staying Curious, Learning New Languages, Frameworks, And Tools.
            </div>
            <div className="justify-self-center pt-[50px] min-[700px]:justify-self-start min-[700px]:pt-0 w-fit">
              Marketing:
            </div>
            <div className="text-[23px] min-[500px]:text-[25px]">
              Understanding User Psychology And Crafting Compelling{" "}
              <span className="text-red-500 ">N</span>arratives.
            </div>
            <div className="justify-self-center pt-[50px] min-[700px]:justify-self-start min-[700px]:pt-0 w-fit">
              <span className="text-red-500 ">B</span> -Management:
            </div>
            <div className="text-[23px] min-[500px]:text-[25px]">
              Navigating the Corporate Seas With Finesse.
            </div>
            <div className="justify-self-center pt-[50px] min-[700px]:justify-self-start min-[700px]:pt-0 w-fit">
              Finance:
            </div>
            <div className="text-[23px] min-[500px]:text-[25px]">
              Because Numbers Tell Stories{" "}
              <span className="text-red-500 ">T</span>oo.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
