import React, { useEffect } from "react";

const Skills = () => {
  useEffect(() => {
    const txtSkills = document.querySelector("#txtSkills");
    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        if (e.isIntersecting) {
          e.target.style.bottom = 0;
        } else {
          e.target.style.bottom = `-100%`;
        }
      });
    });
    observer1.observe(txtSkills);

    // -------------------------------------------------------------------------

    const skillIcon = document.querySelectorAll("#box-1");

    const observer2 = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          if (e.isIntersecting) {
            bubble();
            observer2.unobserve(document.querySelector("#bubble"));
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    observer2.observe(document.querySelector("#bubble"));

    const bubble = () => {
      skillIcon.forEach((e) => {
        e.style.bottom = `${45}%`;
        e.style.left = `${50}%`;
      });

      setInterval(() => {
        skillIcon.forEach((e) => {
          e.style.bottom = `${Math.floor(Math.random() * 80)}%`;
          e.style.left = `${Math.floor(Math.random() * 80)}%`;
        });
      }, 5000);
    };

    // ---------------------------------------------------------------------------------
    const progressBar = document.querySelectorAll("#gradient");

    const container = document.querySelector("#progressContainer");
    const observer3 = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          if (e.isIntersecting) {
            progressBar.forEach((e) => {
              progress(
                e,
                e.querySelector(":first-child").querySelector(":last-child")
              );
            });
            observer3.unobserve(container);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );
    observer3.observe(container);

    const progress = (e, x) => {
      let percent = 0;
      const inter = setInterval(() => {
        percent++;
        e.style.background = `conic-gradient(white ${percent * 3.6}deg,black ${
          percent * 3.6
        }deg)`;
        x.textContent = `${percent}%`;
        if (percent === parseInt(e.dataset.percent)) clearInterval(inter);
      }, 50);
    };
  }, []);

  return (
    <div className="text-white overflow-hidden bg-black min-h-screen py-[200px] px-[20px] min-[500px]:px-[50px]">
      <div className=" flex justify-end items-center h-[55px] py-10">
        <div className="border-red-500 border-b-[2px] w-[130px] h-[50px]  leading-[50px] relative overflow-hidden">
          <p
            id="txtSkills"
            className="text-red-500 text-[50px] text-nowrap absolute w-fit right-0 -bottom-full transition-all duration-1000 "
          >
            Skills
          </p>
        </div>
      </div>
      <div className=" w-full min-[900px]:h-[500px] pt-[100px] box-content flex flex-col min-[900px]:flex-row">
        <div
          id="bubble"
          className=" w-full h-[500px] min-[500px]:h-[300px] min-[900px]:w-[300px]  min-[900px]:h-[500px] min-[1300px]:w-[500px] relative overflow-hidden "
          style={{ boxShadow: "-7px -7px 20px -10px red" }}
        >
          <div
            id="box-1"
            className="grid place-content-center w-[80px] h-[80px] min-[1300px]:h-[100px] min-[1300px]:w-[100px] "
          >
            <img
              src="../assets/html.png"
              alt="htmlImg"
              className=" w-[40px] h-[40px]   min-[1300px]:h-[60px] min-[1300px]:w-[60px] "
            />
          </div>
          <div
            id="box-1"
            className="grid place-content-center w-[80px] h-[80px] min-[1300px]:h-[100px] min-[1300px]:w-[100px] "
          >
            <img
              src="../assets/css.png"
              alt="htmlImg"
              className=" w-[40px] h-[40px]   min-[1300px]:h-[60px] min-[1300px]:w-[60px] "
            />
          </div>
          <div
            id="box-1"
            className="grid place-content-center w-[80px] h-[80px] min-[1300px]:h-[100px] min-[1300px]:w-[100px] "
          >
            <img
              src="../assets/js.png"
              alt="htmlImg"
              className=" w-[40px] h-[40px] rounded-2xl  min-[1300px]:h-[60px] min-[1300px]:w-[60px] "
            />
          </div>
          <div
            id="box-1"
            className="grid place-content-center w-[80px] h-[80px] min-[1300px]:h-[100px] min-[1300px]:w-[100px] "
          >
            <img
              src="../assets/xl.png"
              alt="htmlImg"
              className=" w-[40px] h-[40px]   min-[1300px]:h-[60px] min-[1300px]:w-[60px] "
            />
          </div>
          <div
            id="box-1"
            className="grid place-content-center w-[80px] h-[80px] min-[1300px]:h-[100px] min-[1300px]:w-[100px] "
          >
            <img
              src="../assets/react.png"
              alt="htmlImg"
              className=" w-[40px] h-[40px]   min-[1300px]:h-[60px] min-[1300px]:w-[60px] "
            />
          </div>
          <div
            id="box-1"
            className="grid place-content-center w-[80px] h-[80px] min-[1300px]:h-[100px] min-[1300px]:w-[100px] "
          >
            <img
              src="../assets/cpp.png"
              alt="htmlImg"
              className=" w-[40px] h-[40px]   min-[1300px]:h-[60px] min-[1300px]:w-[60px] "
            />
          </div>
          <div
            id="box-1"
            className="grid place-content-center w-[80px] h-[80px] min-[1300px]:h-[100px] min-[1300px]:w-[100px] "
          >
            <img
              src="../assets/git.png"
              alt="htmlImg"
              className=" w-[40px] h-[40px]   min-[1300px]:h-[60px] min-[1300px]:w-[60px] "
            />
          </div>
          <div
            id="box-1"
            className="grid place-content-center w-[80px] h-[80px] min-[1300px]:h-[100px] min-[1300px]:w-[100px] "
          >
            <img
              src="../assets/tailwind.png"
              alt="htmlImg"
              className=" w-[40px] h-[40px]   min-[1300px]:h-[60px] min-[1300px]:w-[60px] "
            />
          </div>
        </div>
        <div
          style={{
            boxShadow: "7px 7px 20px -10px red",
          }}
          id="progressContainer"
          className=" overflow-hidden min-w-[200px] flex-grow h-full grid  grid-rows-[2fr_1fr_2fr] gap-y-[20px]  min-[600px]:grid-rows-3 p-[20px] box-content"
        >
          <div className=" grid  grid-flow-row gap-y-[20px] min-[600px]:grid-cols-[repeat(2,minmax(200px,1fr))]  min-[1000px]:grid-cols-[repeat(2,minmax(300px,1fr))]">
            <div className="flex justify-between min-[400px]:justify-around items-center">
              <div>
                <div
                  data-percent="80"
                  id="gradient"
                  className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
                >
                  <div id="gradient2">
                    <h3 className="text-[20px] min-[1100px]:text-[25px]">
                      React
                    </h3>
                    <h2 id="gradientPercent" className="text-[20px]">
                      0%
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                <div
                  data-percent="70"
                  id="gradient"
                  className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
                >
                  <div id="gradient2">
                    <h3 className="text-[20px] min-[1100px]:text-[25px]">JS</h3>
                    <h2 id="gradientPercent" className="text-[20px]">
                      0%
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between min-[400px]:justify-around items-center">
              <div>
                <div
                  data-percent="75"
                  id="gradient"
                  className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
                >
                  <div id="gradient2">
                    <h3 className="text-[20px] min-[1100px]:text-[25px]">
                      CSS
                    </h3>
                    <h2 id="gradientPercent" className="text-[20px]">
                      0%
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                <div
                  data-percent="60"
                  id="gradient"
                  className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
                >
                  <div id="gradient2">
                    <h3 className="text-[20px] min-[1100px]:text-[25px]">
                      HTML
                    </h3>
                    <h2 id="gradientPercent" className="text-[20px]">
                      0%
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between min-[400px]:justify-around items-center">
            <div>
              <div
                data-percent="85"
                id="gradient"
                className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
              >
                <div id="gradient2">
                  <h3 className="text-[20px] min-[1100px]:text-[25px]">
                    Front-End
                  </h3>
                  <h2 id="gradientPercent" className="text-[20px]">
                    0%
                  </h2>
                </div>
              </div>
            </div>
            <div>
              <div
                data-percent="60"
                id="gradient"
                className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
              >
                <div id="gradient2">
                  <h3 className="text-[18px] min-[1100px]:text-[20px]">
                    Problem-Solve
                  </h3>
                  <h2 id="gradientPercent" className="text-[20px]">
                    0%
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className=" grid  grid-flow-row gap-y-[20px] min-[600px]:grid-cols-[repeat(2,minmax(200px,1fr))]  min-[1000px]:grid-cols-[repeat(2,minmax(300px,1fr))]">
            <div className="flex justify-between min-[400px]:justify-around items-center">
              <div>
                <div
                  data-percent="85"
                  id="gradient"
                  className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
                >
                  <div id="gradient2">
                    <h3 className="text-[20px] min-[1100px]:text-[25px]">
                      CPP
                    </h3>
                    <h2 id="gradientPercent" className="text-[20px]">
                      0%
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                <div
                  data-percent="80"
                  id="gradient"
                  className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
                >
                  <div id="gradient2">
                    <h3 className="text-[20px] min-[1100px]:text-[25px]">
                      Git
                    </h3>
                    <h2 id="gradientPercent" className="text-[20px]">
                      0%
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between min-[400px]:justify-around items-center">
              <div>
                <div
                  data-percent="20"
                  id="gradient"
                  className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
                >
                  <div id="gradient2">
                    <h3 className="text-[20px] min-[1100px]:text-[25px]">
                      MS-Excel
                    </h3>
                    <h2 id="gradientPercent" className="text-[20px]">
                      0%
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                <div
                  data-percent="75"
                  id="gradient"
                  className=" h-[100px] w-[100px]  min-[1100px]:h-[130px] min-[1100px]:w-[130px]  rounded-full"
                >
                  <div id="gradient2">
                    <h3 className="text-[20px] min-[1100px]:text-[25px]">
                      TailWind
                    </h3>
                    <h2 id="gradientPercent" className="text-[20px]">
                      0%
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
