import React, { useEffect } from "react";

const Navbar = () => {
  useEffect(() => {
    const nav = document.querySelector("#nav");
    nav.style.top = `${window.innerHeight - 60}px`;
  }, []);

  window.addEventListener("resize", () => {
    const nav = document.querySelector("#nav");
    nav.style.top = `${window.innerHeight - 60}px`;
  });

  return (
    <div
      id="nav"
      style={{ boxShadow: "0 0 10px 0 red" }}
      className="fixed right-[15px] z-[999] rounded-full overflow-hidden"
    >
      <a href="#home-div">
        <div className="bg-black  relative h-[40px] w-[40px]">
          <svg
            className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
            width="50"
            height="30"
            viewBox="0 0 290 369"
            fill="none"
          >
            <path
              d="M103.5 0L290 369L103.5 126L0.5 282.5L103.5 0Z"
              fill="white"
            />
          </svg>
        </div>
      </a>
    </div>
  );
};

export default Navbar;
